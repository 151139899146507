<template>
  <CRow>
    <CCol sm="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-grid"/>アカウント一覧
        </CCardHeader>
        <CCardBody>
          <CDataTable
            :items="usersData"
            :fields="fields"
            column-filter
            table-filter
            items-per-page-select
            :items-per-page="10"
            hover
            sorter
            pagination
          >
            <template #role="{item}">
              <td class="text-right">
                {{item.role}}
              </td>
            </template> 

            <template #prefectures_address="{item}">
              <td class="text-right">
                {{item.prefectures_address}}
              </td>
            </template> 

            <template #status="{item}">
              <td>
                <CBadge :color="getBadge(item.status)">
                  {{item.status}}
                </CBadge>
              </td>
            </template>
            <template #show_details="{item, index}">
              <td class="py-2">
                <CButton
                  color="info"
                  variant="outline"
                  square
                  size="sm"
                  @click="toggleDetails(item.id)"
                >
                  {{details.includes(index) ? 'Hide' : '編集'}}
                </CButton>
              </td>
            </template>
            <template #details="{item, index}">
              <CCollapse :show="details.includes(index)">
                <CCardBody>
                  {{index + 1}} - {{item}}
                </CCardBody>
              </CCollapse>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import firebase from '@firebase/app';
import { UserRole } from "@/common/const";
import jpPrefecture from 'jp-prefecture';

const fields = [
  { key: 'role', label: '役割',  _style:'width:10%;' },
  { key: 'name', label: '会社名',  _style:'width:25%' },
  { key: 'prefectures_address', label: '住所',  _style:'width:25%' },
  { key: 'registered', label: '登録日',  _style:'width:10%;' },
  { 
    key: 'show_details', 
    label: '', 
    _style: 'width:10%', 
    sorter: false, 
    filter: false
  }
]

export default {
  name: 'Tables',
  data () {
    return {
      usersData: [],
      fields,
      details: [],
    }
  },
  methods: {
    getBadge (status) {
      return status === 'Active' ? 'success'
             : status === 'Inactive' ? 'secondary'
             : status === 'Pending' ? 'warning'
             : status === 'Banned' ? 'danger' : 'primary';
    },

    toggleDetails (id) {
      const link = `/accounts/${id}`
      this.$router.push({path: link})
    },
    getAuthorityNum() {
      const tmp = this.$route.params.authority
      return tmp === 'makers' ? UserRole.MAKERS
             : tmp === 'distributors' ? UserRole.DISTRIBUTIONS
             : tmp === 'wholesale' ? UserRole.WHOLESALE 
             : tmp === 'register' ? UserRole.REGISTER : 0;
    },
    getAuthorityText(authorityStr) {
      const tmp = this.$route.params.authority
      return tmp === 'makers' ? UserRole.getName(UserRole.MAKERS)
             : tmp === 'distributors' ? UserRole.getName(UserRole.DISTRIBUTIONS)
             : tmp === 'wholesale' ? UserRole.getName(UserRole.WHOLESALE)
             : tmp === 'register' ? UserRole.getName(UserRole.REGISTER) : 'エラー';
    },
    init () {
      // アカウント一括取得
      firebase.firestore().collection('users').get().then(function(result) {
        let tmp = []
        result.forEach(function(doc) {
          let account = doc.data()

          // format
          account.role = UserRole.getName(account.role);
          if (account.prefCode) {
            let pref = jpPrefecture.prefFindBy("id", parseInt(account.prefCode, 10));
            account.prefectures_address = (pref == null ? "" : pref.name) + account.address;
          } else {
            account.prefectures_address = account.prefectures + account.address;
          }
          if (account.registered !== '' && typeof(account.registered) === 'object') {
            if (account.registered != null) {
              const registered = account.registered.toDate()
              account.registered = registered.getFullYear() + '-' + ('0' + (registered.getMonth() + 1)).slice(-2) + '-' + ('0' + registered.getDate()).slice(-2)
            }
          }
          tmp.push(account)
        })
        this.usersData = tmp
      }.bind(this))
    },
  },
  created () {
    this.init()
  },
  watch: {
    '$route': function () {
      this.init()
    }
  },
}
</script>
